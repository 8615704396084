<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12">
        <v-row>
          <v-col md="8" offset-md="2" class="gray-background">
            <div class="p-10">
              <table class="width-100">
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Full name:
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ engineer.emergency_contact_name || " - " }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">
                    Mobile number:
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ engineer.emergency_contact_number || " - " }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Relation:</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ engineer.emergency_contact_relation || " - " }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Email:</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ engineer.emergency_contact_email || " - " }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Address:</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ engineer.emergency_contact_unit_no }},
                    {{ engineer.emergency_contact_street_1 }},
                    {{ engineer.emergency_contact_street_2 }},
                    {{ engineer.emergency_contact_postal_code }}.
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "emergency-contact",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      fieldDescriptions: [],
    };
  },
};
</script>
